import styled from "styled-components";

import { white, black } from "theme/colors";

import { toRem } from "utils/mixins";
import { breakpoints, zIndex } from "utils/breakpoints";

export const Wrapper = styled.section`
  height: 100vh;
  padding-top: ${toRem(90)};
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  z-index: ${zIndex.base};
  overflow: hidden;
  justify-content: center;
  @media ${breakpoints.forTablet} {
    padding-top: ${toRem(120)};
  }
  @media ${breakpoints.forLaptop} {
    padding-top: ${toRem(90)};
  }
  @media ${breakpoints.forDesktop} {
    padding-top: ${toRem(120)};
  }
`;

export const ShowreelContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  padding: 10px 0 50px 0;
  overflow-y: scroll;
  ul {
    height: auto;

    max-width: 94vw;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    z-index: ${zIndex.base};
    h2 {
      display: block;
      position: relative;
      margin: 0;
      font-size: ${toRem(32)};
      transition: 0;
      color: ${(props) => (props.isBlack ? "inherit" : white.default)};
      line-height: 1.05;
      margin-bottom: ${toRem(8)};
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: ${toRem(2)};
        display: block;
        bottom: 0;
        right: 0;
        background-color: ${(props) =>
          props.isBlack ? black.default : white.default};
        transition: 0.4s ease;
      }
    }
  }

  @media ${breakpoints.forBigMobile} {
    ul {
      h2 {
        font-size: ${toRem(32)};
      }
    }
  }

  @media ${breakpoints.forTablet} {
    ul {
      h2 {
        font-size: ${toRem(40)};
      }
    }
  }

  @media ${breakpoints.forBigTablet} {
    padding: 0;
    overflow-y: unset;
    ul {
      h2 {
        margin-bottom: ${toRem(12)};
        font-size: ${toRem(36)};
        &:after {
          height: ${toRem(3)};
        }
      }
    }
  }

  @media (min-width: 799px) {
    ul {
      flex-direction: row;
      h2 {
        margin: 0 ${toRem(15)} ${toRem(12)};
      }
    }
  }

  @media ${breakpoints.forLaptop} {
    ul {
      h2 {
        font-size: ${toRem(38)};
        margin: 0 ${toRem(15)} ${toRem(12)};
      }
    }
  }

  @media (min-width: 1179px) {
    max-width: 70vw;
    ul {
      h2 {
        &:hover {
          &::after {
            width: 100%;
            left: 0;
          }
        }
      }
    }
  }

  @media (min-width: 1365px) {
    max-width: 80vw;
    ul {
      h2 {
        font-size: ${toRem(52)};
        margin: 0 ${toRem(20)} ${toRem(14)};
        &:after {
          height: ${toRem(4)};
        }
      }
    }
  }
  @media ${breakpoints.forDesktop} {
    max-width: 90vw;
    ul {
      h2 {
        font-size: ${toRem(60)};
        margin: 0 ${toRem(35)} ${toRem(14)};
      }
    }
  }

  @media (min-width: 1900px) {
    max-width: 80vw;
    ul {
      h2 {
        font-size: ${toRem(72)};
        &:after {
          height: ${toRem(6)};
        }
      }
    }
  }
  @media ${breakpoints.forBigDesktop} {
    ul {
      h2 {
        font-size: 7vh;
        margin: 0 3vh 1vh;
        &:after {
          height: 0.5vh;
        }
      }
    }
  }
`;

export const Cover = styled.div`
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  // background: ${(props) => (props.isHome ? "red" : "")};
  z-index: -1;

  video {
    object-fit: cover !important;
    background: black;
    height: 100% !important;
    width: 100vw !important;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 2;
    //transition: 230ms;
    transition-delay: 0;
    &.showA {
      display: block;
      top: 0;
      left: 0;
      opacity: 1;
      z-index: 1;
    }
    &.show {
      transition-delay: 0;
      opacity: 1;
    }
  }
`;

export const ImagePreview = styled.div`
  height: 100vh !important;
  width: 100vw !important;
  background: black;
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
  // transition: 230ms;
  transition-delay: 0;

  img {
    object-fit: cover !important;
    height: 100vh !important;
    width: 100vw !important;
    transform: scale(1, 1);
    transition: 2s;
  }

  &.show {
    transition-delay: 0;
    // transition-delay: 0s;
    //transition: 250ms;
    opacity: 1;
    // z-index: 3;
    img {
      transform: scale(1.1, 1.1);
    }
  }
`;
