import React, { useEffect, useRef, useState } from "react";

import { graphql, Link, useStaticQuery } from "gatsby";

import {
  ShowreelContainer,
  ImagePreview,
  Wrapper,
  Cover
} from "./Showreel.styles";

const Showreel = (props) => {
  //PROPS
  const { code, skills, isBlack, setBlack, first, dimensions } = props;
  //

  //DATA
  const data = useStaticQuery(graphql`
    query talents {
      allWpPage {
        edges {
          node {
            talent_order {
              talentOrder {
                ... on WpTalent {
                  skills {
                    nodes {
                      name
                      slug
                      id
                    }
                  }
                  types {
                    nodes {
                      slug
                    }
                  }
                  title
                  uri
                  language {
                    code
                  }
                  talents_info {
                    coverFilmImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 3600, quality: 100) {
                            srcSet
                            src
                          }
                        }
                      }
                    }
                    coverFilmMediaType
                    coverPhotographyMediaType
                    coverPhotographyVideo {
                      mediaItemUrl
                    }
                    defaultCover
                    coverPhotographyImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 3600, quality: 100) {
                            srcSet
                            src
                          }
                        }
                      }
                    }
                    coverFilmVideo {
                      mediaItemUrl
                    }
                    coverVideo {
                      mediaItemUrl
                    }
                    coverType
                    coverImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 3600, quality: 100) {
                            srcSet
                            src
                          }
                        }
                      }
                    }
                  }
                }
              }
              talentOrderReal {
                ... on WpTalent {
                  skills {
                    nodes {
                      name
                      slug
                      id
                    }
                  }
                  types {
                    nodes {
                      slug
                    }
                  }
                  title
                  uri
                  language {
                    code
                  }
                  talents_info {
                    coverFilmImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 3600, quality: 100) {
                            srcSet
                            src
                          }
                        }
                      }
                    }
                    coverFilmMediaType
                    coverPhotographyMediaType
                    coverPhotographyVideo {
                      mediaItemUrl
                    }
                    defaultCover
                    coverPhotographyImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 3600, quality: 100) {
                            srcSet
                            src
                          }
                        }
                      }
                    }
                    coverFilmVideo {
                      mediaItemUrl
                    }
                    coverVideo {
                      mediaItemUrl
                    }
                    coverType
                    coverImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 3600, quality: 100) {
                            srcSet
                            src
                          }
                        }
                      }
                    }
                  }
                }
              }
              talentOrderFoto {
                ... on WpTalent {
                  skills {
                    nodes {
                      name
                      slug
                      id
                    }
                  }
                  types {
                    nodes {
                      slug
                    }
                  }
                  title
                  uri
                  language {
                    code
                  }
                  talents_info {
                    coverFilmImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 3600, quality: 100) {
                            srcSet
                            src
                          }
                        }
                      }
                    }
                    coverFilmMediaType
                    coverPhotographyMediaType
                    coverPhotographyVideo {
                      mediaItemUrl
                    }
                    defaultCover
                    coverPhotographyImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 3600, quality: 100) {
                            srcSet
                            src
                          }
                        }
                      }
                    }
                    coverFilmVideo {
                      mediaItemUrl
                    }
                    coverVideo {
                      mediaItemUrl
                    }
                    coverType
                    coverImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 3600, quality: 100) {
                            srcSet
                            src
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            language {
              code
            }
          }
        }
      }
    }
  `);

  const {
    allWpPage: { edges }
  } = data;

  //DATA

  //STATES
  const [talents, setTalents] = useState([]);
  const videoContainer = useRef(null);
  const [media, setMedia] = useState(null);
  //STATES

  //COMPONENTS
  const ImgPreview = (props) => {
    const { uri, index, image } = props;
    return (
      <ImagePreview className={media === uri ? "show" : ""} key={index}>
        <img
          src={image?.localFile?.childImageSharp.fluid.src}
          srcSet={image?.localFile?.childImageSharp.fluid.srcSet}
          alt={image?.altText}
        />
      </ImagePreview>
    );
  };

  const VideoPreview = (props) => {
    const { uri, index, video } = props;
    return (
      <video
        playsInline
        ref={videoContainer}
        autoPlay
        key={index}
        loop
        muted
        className={media === uri ? "show" : ""}
        // src={media === uri ? video?.mediaItemUrl : null}
        src={video?.mediaItemUrl}
      />
    );
  };
  //COMPONENTS

  //USEEFFECT
  useEffect(() => {
    let langFilteredTalents = edges
      .filter((e) => e.node.language.code === code)
      .filter((e) => e.node.talent_order.talentOrder);

    setTalents();
    if (skills === "photographer") {
      setTalents(langFilteredTalents[0].node?.talent_order.talentOrderFoto);
    } else if (skills === "director") {
      setTalents(langFilteredTalents[0].node?.talent_order.talentOrderReal);
    } else {
      setTalents(langFilteredTalents[0].node?.talent_order.talentOrder);
    }
  }, [code, skills]);
  //USEEFFECT

  return (
    <Wrapper first={first} id="showreel">
      <ShowreelContainer isBlack={isBlack}>
        <ul>
          {talents &&
            talents.map((talent, index) => {
              let tal = talent;

              return (
                <li
                  key={index}
                  onMouseEnter={() => {
                    setMedia(talent.uri);
                    setBlack(false);
                  }}
                  onMouseLeave={() => {
                    setMedia(null);
                    if (!props.isHome) {
                      setBlack(true);
                    }
                  }}
                  onClick={() => {
                    setMedia(null);
                  }}
                >
                  <Link to={tal.uri} id={tal.uri + "o"}>
                    <h2>{tal.title}</h2>
                  </Link>
                </li>
              );
            })}
        </ul>
        <Cover isHome={props.isHome ? true : false}>
          {talents &&
            !dimensions.isMobile &&
            talents.map((talent, index) => {
              if (talent.skills?.nodes.length > 1) {
                if (skills === "photographer") {
                  return talent.talents_info?.coverPhotographyMediaType ===
                    "Image"
                    ? ImgPreview({
                        uri: talent.uri,
                        index: index,
                        image: talent.talents_info?.coverPhotographyImage
                      })
                    : VideoPreview({
                        uri: talent.uri,
                        index: index,
                        video: talent.talents_info?.coverPhotographyVideo
                      });
                } else {
                  return talent.talents_info?.coverFilmMediaType === "Image"
                    ? ImgPreview({
                        uri: talent.uri,
                        index: index,
                        image: talent.talents_info?.coverFilmImage
                      })
                    : VideoPreview({
                        uri: talent.uri,
                        index: index,
                        video: talent.talents_info?.coverFilmVideo
                      });
                }
              } else if (talent.skills.nodes.length > 1 && !skills) {
                if (talent.talents_info?.defaultCover === "Image") {
                  return talent.talents_info?.coverPhotographyMediaType ===
                    "Image"
                    ? ImgPreview({
                        uri: talent.uri,
                        index: index,
                        image: talent.talents_info?.coverPhotographyImage
                      })
                    : VideoPreview({
                        uri: talent.uri,
                        index: index,
                        video: talent.talents_info?.coverPhotographyVideo
                      });
                } else {
                  return talent.talents_info?.coverFilmMediaType === "Image"
                    ? ImgPreview({
                        uri: talent.uri,
                        index: index,
                        image: talent.talents_info?.coverFilmImage
                      })
                    : VideoPreview({
                        uri: talent.uri,
                        index: index,
                        video: talent.talents_info?.coverFilmVideo
                      });
                }
              } else {
                if (
                  talent.talents_info?.coverType === "Image" &&
                  talent.talents_info?.coverImage
                ) {
                  return ImgPreview({
                    uri: talent.uri,
                    index: index,
                    image: talent.talents_info?.coverImage
                  });
                } else if (
                  talent.talents_info?.coverType === "Video" &&
                  talent.talents_info?.coverVideo
                ) {
                  return VideoPreview({
                    uri: talent.uri,
                    index: index,
                    video: talent.talents_info?.coverVideo
                  });
                }
              }
            })}
          {props?.videoCover?.mediaItemUrl && (
            <video
              ref={videoContainer}
              autoPlay
              loop
              playsInline
              muted
              className="showA"
              src={props.videoCover.mediaItemUrl}
            />
          )}
        </Cover>
      </ShowreelContainer>
    </Wrapper>
  );
};

export default Showreel;
